<template>
  <div>
    <v-data-table data-cy="carriersTable" :items="carriers" :headers="headers">
      <template v-slot:item="{ item }">
        <tr data-cy="carriersTableRow" :key="item.carrierId">
          <td>{{ item.carrierName }}</td>
          <td>
            <v-icon data-cy="deleteCarrier" @click.stop="deleteCarrier(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-dialog v-model="showDeleteDialog">
          <v-card>
            <v-form data-cy="deleteConfirmationDialog">
              <v-card-title>Delete Carrier</v-card-title>
              <v-card-text>
                Delete carrier <strong>{{ carrier.carrierName }}</strong>?
              </v-card-text>
              <v-alert v-if="cannotDeleteCarrier" type="error">
                Cannot delete this carrier
              </v-alert>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="deleteCarrierClose" @click.stop="closeDeleteDialog"> Cancel </v-btn>
                <v-btn data-cy="deleteCarrierOk" color="warning" @click.stop="okDeleteCarrier(carrier)">
                  Delete
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-toolbar>
          <v-dialog v-model="addDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn data-cy="addCarrier" color="primary" class="mb-2" v-bind="attrs" v-on="on">Add Carrier</v-btn>
            </template>
            <v-card min-width="400px">
              <v-form data-cy="addCarrierForm">
                <v-card-title>
                  <span class="text-h5">Add Carrier</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field data-cy="carrierName" v-model="carrier.carrierName" label="Carrier Name">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field data-cy="carrierTrackingUrl" v-model="carrier.carrierTrackingUrl"
                          label="Carrier Tracking URL">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn data-cy="addCarrierClose" @click.stop="closeAddDialog"> Cancel </v-btn>
                  <v-btn data-cy="addCarrierSave" color="primary" @click.stop="saveAddedItem(carrier)">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'CarriersList',
  components: {},

  data: () => ({
    carriers: [],
    headers: [
      { text: 'Carrier', align: 'start', value: 'name', sortable: true }
    ],
    addDialog: false,
    showDeleteDialog: false,
    cannotDeleteCarrier: false,
    carrier: {
      carrierName: '',
      carrierTrackingUrl: ''
    }
  }),

  mounted: function () {
    this.loadCarriers()
  },

  methods: {
    loadCarriers () {
      fetch('/api/shipping/carriers', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.carriers = data.carriers
        })
    },

    deleteCarrier (carrier) {
      this.carrier = carrier
      this.showDeleteDialog = true
      this.cannotDeleteCarrier = false
    },

    closeDeleteDialog () {
      this.showDeleteDialog = false
    },

    okDeleteCarrier (carrier) {
      fetch(`/api/shipping/carriers/${carrier.carrierId}`, {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + `${this.$keycloak.token}` }
      })
        .then((response) => {
          if (response.status === 200) {
            this.showDeleteDialog = false
            this.loadCarriers()
          } else {
            this.cannotDeleteCarrier = true
          }
        })
    },

    closeAddDialog () {
      this.addDialog = false
    },

    saveAddedItem (carrier) {
      fetch('/api/shipping/carriers', {
        method: 'POST',
        headers: { 'Content-type': 'application/json', Authorization: 'Bearer ' + `${this.$keycloak.token}` },
        body: JSON.stringify(carrier)
      })
        .then((response) => {
          if (response.status === 201) {
            this.addDialog = false
            this.carrier = {}
            this.loadCarriers()
          }
        })
    }
  }
}
</script>
