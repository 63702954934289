<template>
  <div>
    <v-data-table :items="shippedOrders" :headers="headers">
      <template v-slot:item="{ item }">
        <tr :key="item.orderId">
          <td>{{ $d(Date.parse(item.shippingDateTime), 'short') }}</td>
          <td>{{ item.customer.name }}</td>
          <td><a target="_blank" :href="item.trackingUrl">{{ item.trackingUrl }}</a></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'SentOrders',
  components: {},

  data: () => ({
    shippedOrders: [],
    headers: [
      { text: 'Shipping Date', align: 'start', value: 'shippingDateTime', sortable: true },
      { text: 'Customer', align: 'start', value: 'customer.name', sortable: true },
      { text: 'Tracking', align: 'start', value: 'trackingUrl', sortable: false }
    ]
  }),

  beforeMount: function () {
    this.loadOrders()
  },

  methods: {
    loadOrders () {
      fetch('/api/shipping/orders/shipped', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.shippedOrders = data.shippingOrders
        })
    }
  }
}
</script>
