<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item" :data-cy="item">{{ item }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="PendingOrders">
        <PendingOrders />
      </v-tab-item>
      <v-tab-item :key="SentOrders">
        <SentOrders />
      </v-tab-item>
      <v-tab-item :key="Carriers">
        <Carriers />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PendingOrders from './PendingOrders.vue'
import Carriers from './Carriers.vue'
import SentOrders from './SentOrders.vue'

export default {
  name: 'ShippingMain',
  components: {
    PendingOrders,
    Carriers,
    SentOrders
  },

  data () {
    return {
      tab: null,
      items: [
        'Pending Orders', 'Sent Orders', 'Carriers'
      ]
    }
  }
}
</script>
