<template>
  <div>
    <v-data-table data-cy="shippingOrders" :items="orders" :headers="headers" items-per-page="30">
      <template v-slot:item="{ item }">
        <tr :key="item.orderId" @click="shipTo(item)">
          <td>{{ item.customer.name }}</td>
          <td>
            <span style="padding-right: 5px; display: block" v-for="(name, index) in item.productNames" :key="index">{{
              name
            }}
            </span>
          </td>
          <td>
            <v-icon data-cy="sendMail" @click.stop="sendMessageDialog(item)">mdi-email</v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-dialog v-model="detailsDialog">
          <v-card>
            <v-form data-cy="shippingDialog">
              <v-card-title>Shipping details</v-card-title>
              <v-card-text>

                <v-container>
                  <v-row>
                    <v-col>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Product
                              </th>
                              <th class="text-left">
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in selectedOrder.productItems" :key="item.productItem.id">
                              <td>{{ item.productItem.productName }}</td>
                              <td>{{ item.quantity }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      {{ selectedOrder.customer.address }}
                      <pre>
                          {{ selectedOrder.customer.name }}
                          {{ selectedOrder.customer.phone }}
                          {{ selectedOrder.customer.shippingAddress.addressLine1 }}
                          {{ selectedOrder.customer.shippingAddress.addressLine2 }}
                          {{ selectedOrder.customer.shippingAddress.postalCode }} {{ selectedOrder.customer.shippingAddress.adminArea1 }}, {{ selectedOrder.customer.shippingAddress.adminArea2 }}
                          {{ selectedOrder.customer.shippingAddress.countryCode }}
                          </pre>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-radio-group v-model="selectedOrder.trackingUrl">
                        <v-radio data-cy="shipWithoutTrackingNumber" label="None" value="NONE"></v-radio>
                        <v-radio v-for="carrier in carriersAvailable" :key="carrier.carrierId"
                          :data-cy="carrier.carrierName" :label="carrier.carrierName" :value="carrier.carrierTrackingUrl">
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-text-field data-cy="trackingNumber" v-model="selectedOrder.trackingNumber"
                        label="Tracking Number">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="cancelShipping" @click="closeDetailsDialog()">Cancel</v-btn>
                <v-btn data-cy="performShipping" @click="performShipping(selectedOrder)" color="primary">Shipped</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog width="600" v-model="messageDialog" class="min-w-xl">
          <v-card>
            <v-form data-cy="messageDialog">
              <v-card-title>Contact Customer</v-card-title>
              <v-card-text>
                <v-text-field data-cy="messageSubject" v-model="message.subject" label="Message subject">
                </v-text-field>
                <v-textarea name="messageContent" label="Message" auto-grow v-model="message.content">
                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="cancelMessage" @click="closeMessageDialog()">Cancel</v-btn>
                <v-btn data-cy="sendMessage" @click="sendMessage(selectedOrder)" color="primary">Send</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'PendingOrders',
  components: {},

  data: () => ({
    message: {
      subject: '',
      content: ''
    },
    selectedOrder: {
      trackingUrl: '',
      productNames: [],
      productItems: [],
      customer: {
        name: '',
        shippingAddress: {}
      }
    },
    detailsDialog: false,
    messageDialog: false,
    orders: [],
    headers: [
      { text: 'Customer', align: 'start', value: 'customer.name', sortable: false },
      { text: 'Products', align: 'start', value: 'productNames', sortable: false },
      { text: 'Actions', align: 'start', value: 'actions', sortable: false }
    ]

  }),

  mounted: function () {
    this.loadOrders()
    this.loadCarriers()
  },

  methods: {
    loadOrders () {
      fetch('/api/shipping/orders', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          const orders = data.shippingOrders
          orders.forEach(order => {
            order.productNames = []
            order.productItems.forEach(product => {
              order.productNames.push(product.productItem.productName)
            })
          })

          this.orders = orders
        })
    },

    loadCarriers () {
      fetch('/api/shipping/carriers', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.carriersAvailable = data.carriers
        })
    },

    shipTo (order, row) {
      console.log(row)
      this.selectedOrder = { ...order }
      this.detailsDialog = true
    },

    closeDetailsDialog () {
      this.detailsDialog = false
    },

    performShipping (order) {
      let trackingUrl = ''

      this.detailsDialog = false

      if (order.trackingUrl !== 'NONE') { trackingUrl = order.trackingUrl + order.trackingNumber }

      fetch(`/api/shipping/orders/${order.orderId}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          orderId: order.orderId,
          status: 'SHIPPED',
          trackingUrl: trackingUrl
        })
      })
    },

    sendMessageDialog (order) {
      this.selectedOrder = order
      this.messageDialog = true
    },

    closeMessageDialog () {
      this.messageDialog = false
    },

    sendMessage (order) {
      fetch('/api/shipping/orders/send-message', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          orderId: order.orderId,
          subject: this.message.subject,
          content: this.message.content
        })

      })
      this.messageDialog = false
    }
  }
}
</script>
